<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
              <b-button @click="addEmailToSeries">Add Email to Series</b-button>
          </div>
        </div>
      </div>
    </div>
    <h2>Current Email Series</h2>
    <div class="mt-2">
      <div v-pre class="d-block">{{first_name}}, {{last_name}}, {{trial_end_date}}, and {{trial_days_left}} can be used as merge tags in the subject and email body</div>
    </div>
    <ul class="text-danger">
      <li v-for="error in errors" :key="error">{{error}}</li>
    </ul>
    <draggable v-model="emailSeries" group="people" >
      <div v-for="(element,i) in orderedSeries" :key="i" class="row" >
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4><div class="float-left">
                Email: #{{i + 1}}
              </div> <b-button class="bg-danger border-0 float-right" @click="removeEmailToSeries(i)"><feather class="icon-xs align-middle" type="trash" /></b-button></h4>
              <div class="clearfix"></div>
              <label>From Name</label>
              <b-input v-model="element.from_name" />
              <label class="mt-3">From Email</label>
              <b-input v-model="element.from_email" />
              <label class="mt-3">Days to Delay</label>
              <b-input v-model="element.delay_days" type="number" />
              <label class="mt-3">Subject<small></small></label>
              <b-input v-model="element.subject" />
              <label class="mt-3">Email Body</label>
              <vue2-tinymce-editor :id="'email_message' + i" v-model="element.email_body" :options="options"></vue2-tinymce-editor>
            </div>
          </div>
        </div>
      </div>
    </draggable>
    <b-form-group class="mt-3">
      <b-button
          variant="primary"
          class="btn-dark-blue"
          :disabled="loadingUpdate"
          type="submit"
          @click="updateEmailSeries"
      >
        <b-spinner v-if="loadingUpdate" small />
        <span v-else>Update</span>
      </b-button>
    </b-form-group>
  </div>
</template>

<script>
import Vue from "vue";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import draggable from 'vuedraggable'
export default {
  components:{
    Vue2TinymceEditor,
    draggable
  },
  data() {
    return {
      loadingUpdate:false,
      user:{
        settings:{
          misc:{

          }
        }
      },
      emailSeries:[],
      errors:[],
      options:{
        convert_urls: false
      }
    }
  },

  computed:{
    orderedSeries: function () {
      function compare(a, b) {
        return (a.delay_days - b.delay_days);
      }
      return this.emailSeries.slice().sort(compare);
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.emailSeries = this.user.settings.misc?.email_series ?? [];
  },

  methods: {
    addEmailToSeries(){
      this.emailSeries.push( {
        from_name:"",
        from_email:"",
        subject:'',
        email_body:"",
        delay_days:this.emailSeries.length,
      });
    },
    removeEmailToSeries(email){
      this.emailSeries.splice(email,1);
    },
    updateEmailSeries(){
      this.errors = [];
      this.orderedSeries.forEach(function(email, i){
        const ind = i + 1;
        if(!email.subject){
          this.errors.push("Subject #" + ind + " cannot be empty");
        }
        if(!email.from_name){
          this.errors.push("From Name #" + ind + " cannot be empty");
        }
        if(!/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email.from_email)){
          this.errors.push("Email #" + ind + " is not a valid email");
        }
        if(!/^\d*$/.test(email.delay_days)){
          this.errors.push("Delay Days #" + ind + " must be a positive number");
        }
        if(!email.email_body){
          this.errors.push("Email Body #" + ind + " cannot be empty");
        }
      },this);
      if(this.errors.length){
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return false;
      }
      this.loadingUpdate = true
      let params = {
        email_series: this.emailSeries,
        setting_form:'email_series',
        admin_setting_id:this.user.settings.id
      }
      this.$store
          .dispatch('app/editAdminSettings', params)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              console.log(err.response.data.errors);
            }
            this.loadingUpdate = false
          })
    },
  },
}
</script>
